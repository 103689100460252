import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IndicationsService } from 'src/app/services/indications.service';
import { LoginService } from 'src/app/services/login.service';
import { PatientService } from 'src/app/services/patient.service';
import { UiAddPatientComponent } from 'src/app/shared/components/ui-add-patient/ui-add-patient.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-indication',
  templateUrl: './indication.component.html',
  styleUrls: ['./indication.component.scss']
})
export class IndicationComponent implements OnInit {
  indicationId: string = '';
  patientId: string = '';
  loading = false;
  selectedPatient = null;
  activeFiles = [];
  fg = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    patient: new UntypedFormControl(null, [Validators.required]),
    about: new UntypedFormControl('', [Validators.required]),
    file: new UntypedFormControl([]),
    benefit: new UntypedFormControl('', [Validators.required]),
    quantity: new UntypedFormControl(''),
    otherDetails: new UntypedFormControl(''),
    profession: new UntypedFormControl('', [Validators.required]),
  });
  benefits = [
    'Incapacidade temporária',
    'Incapacidade permanente',
    'Incapacidade por tempo indeterminado',
    'BPC-LOAS (Benefício de Prestação Continuada)',
    'Auxílio-acidente',
    'Isenção de imposto de renda',
    'Aposentadoria por tempo de contribuição ao deficiente',
    'Outros'
  ];
  quantities = [
    '30',
    '60',
    '90',
    '120',
    '180',
    'Mais de 180'
  ];
  communicarePatientId = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private dialog: MatDialog,
    private loginService: LoginService,
    private indicationService: IndicationsService,
    private patientService: PatientService
  ) {
    this.patientId = activatedRoute.snapshot.params['patient'];
    this.indicationId = activatedRoute.snapshot.params['id'];
  }

  ngOnInit(): void {
    if (this.indicationId !== 'novo') {
      this.load();
    } else {
      if (this.patientId) {
        this.fg.controls.patient.disable();
        console.log(this.patientId)
        this.patientService.getPatientById(Number(this.patientId)).subscribe({
          next: patient => {
            console.log(patient)
            this.fg.controls.patient.setValue(patient.id);
            this.communicarePatientId = patient.communicarePatientId;
            this.selectedPatient = patient;
          },
          error: error => {
            this.loginService.showMessage('Erro ao buscar dados do paciente', 3000);
            this.loading = false;
          }
        });
      }
    }
  }

  load() {
    this.loading = true;
    this.fg.controls.patient.disable();

    if (this.indicationId) {
      this.indicationService.getById(Number(this.indicationId)).subscribe({
        next: res => {
          if (res.status) {
            this.applyData(res.data)
          } else {
            this.loginService.showMessage('Erro ao buscar dados da indicação', 3000);
            this.cancel();
          }
        },
        error: error => {
          this.loginService.showMessage('Erro ao buscar lista de indicações', 3000);
          this.loading = false;
        }
      });
    }
  }

  applyData(indication) {
    this.selectedPatient = indication.patient;

    this.fg.patchValue({
      id: indication.id,
      patient: indication.patient.id,
      about: indication.observation,
      file: indication.documentUrl,
      benefit: indication.benefit,
      quantity: indication?.daysQtd?.toString(),
      otherDetails: indication?.otherDetails?.toString(),
      profession: indication?.profession?.toString(),
    });

    this.activeFiles = indication.file;

    this.loading = false;
  }

  onChangePaciente(patient: any) {
    if ("isTrusted" in patient)
      return;

    this.communicarePatientId = patient.communicarePatientId

    this.fg.controls.patient.setValue(patient.id);
  }

  onFileSelect(event: Event) {
    const selectedFiles = (event.target as HTMLInputElement).files;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.activeFiles.push({ id: null, file: selectedFiles[i], isDeleted: 0, name: '', originalName: '' });
    }
  }

  removeFile(index: number) {
    const fileInfo = this.activeFiles[index];
    if (fileInfo.id)
      this.activeFiles[index].isDeleted = 1;
    else
      this.activeFiles.splice(index, 1);
  }

  onBenefitChange() {
    if (this.fg.value.benefit !== 'Incapacidade temporária')
      this.fg.controls.quantity.reset();

    if (this.fg.value.benefit !== 'Outros')
      this.fg.controls.otherDetails.reset();
  }

  goToFile(uploadFile) {
    if (uploadFile.originalName) {
      const url = `${environment.api}/files/download/indication/${this.indicationId}/${uploadFile.name}`;
      window.open(url, '_blank')
    }
  }

  async save() {
    if (this.fg.invalid) {
      this.loginService.showMessage('Preencha os campos corretamnte', 3000);
      return;
    }

    const formData = new FormData();

    const filesUploaded = [];

    if (this.activeFiles.length > 0) {
      for await (const uploadFile of this.activeFiles) {
        if (!uploadFile.id) {
          formData.append(`files`, uploadFile.file);
        } else {
          filesUploaded.push(uploadFile)
        }
      }
    }

    this.loading = true;
    this.fg.controls.file.setValue(filesUploaded);
    this.indicationService.save(this.fg.getRawValue()).subscribe(
      async res => {
        if (res.status) {
          if (formData.has('files')) {
            this.fg.controls.id.setValue(res.data.id);
            await this.uploadFiles(formData, res.data.id);
          } else {
            this.loginService.showMessage('Indicação salva com sucesso', 3000);
            await this.navigateToIndications();
            this.loading = false;
          }
        } else {
          this.loginService.showMessage('Erro ao salvar indicação, tente novamente', 3000);
          this.loading = false;
        }
      },
      error => {
        this.loginService.showMessage('Erro ao salvar indicação, tente novamente', 3000);
        this.loading = false;
      }
    );

  }

  async uploadFiles(formData: FormData, indicationId: number) {

    this.indicationService.uploadFiles(formData, indicationId).subscribe(
      async res => {
        if (res.status) {
          this.loginService.showMessage('Indicação salva com sucesso', 3000);
          this.navigateToIndications()
        }
        else {
          this.loginService.showMessage('Erro ao salvar arquivos na indicação, tente novamente', 3000);
          this.loading = false;
        }
      },
      error => {
        this.loginService.showMessage('Erro ao salvar arquivos na indicação, tente novamente', 3000);
        this.loading = false;
      }
    );
  }

  cancel() {
    // back to last page
    this.route.navigate(['/indications'])
  }

  async navigateToIndications() {
    if (this.indicationId === 'novo') {
      if (this.communicarePatientId) {
        await this.route.navigate(['/indications-scheduling/' + this.communicarePatientId])
      } else {
        const patient = await this.patientService.getPatientById(this.fg.value.patient).toPromise();

        if (patient.communicarePatientId) {
          await this.route.navigate(['/indications-scheduling/' + patient.communicarePatientId]);
        } else {
          await this.route.navigate(['/indications']);
        }
      }
    } else {
      await this.route.navigate(['/indications']);
    }
  }

  addPatient() {
    const dialogRef = this.dialog.open(UiAddPatientComponent, {
      disableClose: false
    })

    dialogRef.afterClosed().subscribe(
      res => {
        if (res?.patient) {
          this.selectedPatient = res.patient;
          this.onChangePaciente(res.patient);
        }
      }
    )
  }

  triggerFileInput() {
    const fileInput = document.getElementById('fileBtn') as HTMLInputElement;
    fileInput.click();
  }

}
