import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Views } from '../../views';
import { Title } from '@angular/platform-browser';
import { cpfValidator } from 'src/app/util/cpf-validator';
import { PatientService } from 'src/app/services/patient.service';
import { Patient } from 'src/app/interfaces/patient.interface';
import * as moment from 'moment';
import { LoginService } from 'src/app/services/login.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-patient-register',
  templateUrl: './patient-register.component.html',
  styleUrls: ['./patient-register.component.scss']
})
export class PatientRegisterComponent extends Views implements OnInit {
  patientId: number | string;
  saving: boolean = false;

  genderList = [
    { value: 'F', label: 'Feminino' },
    { value: 'M', label: 'Masculino' },
    { value: 'I', label: 'Indeterminado' },
  ];

  constructor(
    public title: Title,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private patientService: PatientService,
    private loginService: LoginService,
  ) {
    super();
  }

  ngOnInit(): void {
    const paramsId = this.activatedRoute.snapshot.params['id'];
    if (paramsId && paramsId != 'new') {
      this.patientId = this.activatedRoute.snapshot.params['id'];
    }

    let titleAction = this.patientId ? 'Editar' : 'Cadastrar';

    this.title.setTitle(`${titleAction} Cliente`);

    this.campos = {
      id: [],
      isDeleted: [],
      cpf: [Validators.required, cpfValidator()],
      name: [Validators.required],
      gender: [Validators.required],
      birthdate: [Validators.required],
      mobile: [Validators.required],
      email: [Validators.required],
    }

    this.initForm(this.fb)

    this.getAndSetPatient()
  }

  cancel() {
    this.router.navigate(['/patients']);
  }

  async getAndSetPatient(): Promise<void> {
    try {
      if (!this.patientId) {
        this.resetForm()
        return null
      };

      const patient = await this.getPatientById()

      if (patient) {
        if (patient.id) this.fg.get('id').setValue(patient.id);
        if (patient.isDeleted != null) this.fg.get('isDeleted').setValue(patient.isDeleted);

        if (patient.cpf) this.fg.get('cpf').setValue(patient.cpf);
        if (patient.name) this.fg.get('name').setValue(patient.name);
        if (patient.email) this.fg.get('email').setValue(patient.email);
        if (patient.mobile) this.fg.get('mobile').setValue(patient.mobile);
        if (patient.gender) this.fg.get('gender').setValue(patient.gender);

        if (patient.birthdate) {
          const newDate = moment(patient.birthdate).format('DD/MM/YYYY');
          this.fg.get('birthdate').setValue(newDate);
        }
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getPatientById(): Promise<Patient> {
    try {
      if (!this.patientId || this.patientId == 'new') return null;
      const response = await this.patientService.getPatientById(parseInt(this.patientId?.toString())).toPromise()
      return response
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async savePatient() {
    if (this.fg.invalid) {
      return this.loginService.showMessage('Preencha todos os campos corretamente');
    }

    const patient = <Patient>this.fg.getRawValue();
    patient.birthdate = moment.utc(patient.birthdate, "DDMMYYYY").format("YYYY-MM-DD");

    if (patient) {
      this.saving = true;
      this.patientService.savePatient(patient)
        .subscribe({
          next: async (savedPatient) => {
            const action = this.patientId ? 'atualizado' : 'cadastrado';
            this.loginService.showMessage(`Cliente ${action} com sucesso!`, 3000);

            if (this.patientId) {
              await this.router.navigate(['/patients']);
              this.saving = false;
              return;
            }

            await this.router.navigate([`/indication/novo/${savedPatient.id}`]);
            this.saving = false;
          },
          error: (error) => {
            console.log(error);
            const action = this.patientId ? 'atualizar' : 'cadastrar';
            this.loginService.showMessage(`Falha ao ${action} cliente.`, 3000);
          }
        });
    }
  }

  resetForm() {
    this.initForm(this.fb)
  }

  onChangeEmail() {
    if (!this.loginService.isEmailPatternValid(this.fg.controls.email.value)) {
      this.fg.controls.email.setErrors({
        invalid: true
      })
    }
  }

  async onChangeCPF() {
    const cpf = this.fg.controls.cpf.value;

    if (!cpf) {
      this.fg.controls.cpf.setErrors({
        required: true
      })
      return;
    }

    if (!this.loginService.isCpfPatternValid(cpf)) {
      this.fg.controls.cpf.setErrors({
        invalid: true
      })
      return;
    }

    const exists = await this.patientService.patientExist(cpf).toPromise();

    if (exists) {
      this.fg.controls.cpf.setErrors({
        alreadyExist: true
      })
      return;
    }
  }

}
