import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Patient } from 'src/app/interfaces/patient.interface';
import { Views } from 'src/app/pages/views';
import { LoginService } from 'src/app/services/login.service';
import { PatientService } from 'src/app/services/patient.service';
import { cpfValidator } from 'src/app/util/cpf-validator';

@Component({
  selector: 'app-ui-add-patient',
  templateUrl: './ui-add-patient.component.html',
  styleUrls: ['./ui-add-patient.component.scss']
})
export class UiAddPatientComponent extends Views implements OnInit {
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<UiAddPatientComponent>,
    private fb: FormBuilder,
    private loginService: LoginService,
    private patientService: PatientService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.campos = {
      id: [],
      isDeleted: [],
      cpf: [Validators.required, cpfValidator()],
      name: [Validators.required],
      birthdate: [Validators.required],
      mobile: [Validators.required],
      email: [Validators.required],
    }

    this.initForm(this.fb)
  }

  onChangeEmail() {
    if (!this.loginService.isEmailPatternValid(this.fg.controls.email.value)) {
      this.fg.controls.email.setErrors({
        invalid: true
      })
    }
  }

  async onChangeCPF() {
    const cpf = this.fg.controls.cpf.value;

    if (!cpf) {
      this.fg.controls.cpf.setErrors({
        required: true
      })
      return;
    }

    if (!this.loginService.isCpfPatternValid(cpf)) {
      this.fg.controls.cpf.setErrors({
        invalid: true
      })
      return;
    }

    const exists = await this.patientService.patientExist(cpf).toPromise();

    if (exists) {
      this.fg.controls.cpf.setErrors({
        alreadyExist: true
      })
      return;
    }
  }

  async savePatient() {
    if (this.fg.invalid) {
      this.loginService.showMessage('Preencha todos os campos corretamente');
      return;
    }

    const patient = this.fg.getRawValue() as Patient;
    patient.birthdate = moment.utc(patient.birthdate, 'DDMMYYYY').format('YYYY-MM-DD');

    if (patient) {
      try {
        this.loading = true;
        const response = await this.patientService.savePatient(patient).toPromise();

        if (response) {
          this.loginService.showMessage(`Cliente cadastrado com sucesso`, 3000);
          this.dialogRef.close({
            patient: {
              id: response.id,
              name: response.name
            }
          });
          this.loading = false;
        } else {
          this.loginService.showMessage(`Falha ao cadastrar cliente`, 3000);
        }

        this.loading = false;
      } catch (error) {
        let message = 'Falha ao cadastrar cliente'

        if (error.error?.message) {
          message = error.error.message
        }

        this.loginService.showMessage(message, 3000)
        this.loading = false;
      }
    }
  }

}
